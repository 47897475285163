import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { Eloqua } from 'src/app/models/interfaces/eloqua';
import { SelectBoxOption } from '@interfaces/forms';

import { EloquaInputTypeEnum, ValidationTypesEnum } from '@models/enums';
import { EloquaSubmissionBody } from '@interfaces/eloqua/eloqua-form-submission';
import { IEloquaUser } from '@interfaces/eloqua/eloqua-user';

import { EloquaApi } from '@lib/apis/eloqua-api';
import { EloquaFormCache } from '@lib/utils/eloqua/eloqua-form-cache';
import { EloquaDataEnricher } from '@lib/utils/eloqua/eloqua-data-enricher';
import { SpsLogger } from '@lib/utils/logger/sps-logger';
import { SpsStorageService } from '@services/storage/sps-storage.service';

import { LOCALSTORAGE_ELOQUA_USER_KEY } from '@core/config';
import { GatedDocumentConfig } from '@models/interfaces';

@Injectable({ providedIn: 'root' })
export class EloquaService {
  private _user: IEloquaUser = null;
  private formId: number = null;
  private optionLists: { [formId: number]: Eloqua.Options.List } = {};

  constructor(private api: EloquaApi, private cache: EloquaFormCache, private enricher: EloquaDataEnricher) {
    this.restoreUserId();
  }

  get user(): IEloquaUser {
    return this._user || { id: null, email: null };
  }

  set user(user: Partial<IEloquaUser>) {
    this._user = { ...this._user, ...user };
    // Email is considered personal information and may not be persisted -> store id only.
    SpsStorageService.updateEntry<typeof user>(LOCALSTORAGE_ELOQUA_USER_KEY, { id: this._user.id });
  }

  public async getUser(): Promise<IEloquaUser> {
    if (this.user.id && this.user.email) {
      return this.user;
    }

    if (this.user.id) {
      const email = await this.getEmailAddress();
      this.user = { ...this.user, email };

      return this.user;
    }

    if (this.user.email) {
      const id = await this.getEloquaUserId();
      this.user = { ...this.user, id };

      return this.user;
    }

    return null;
  }

  public async buildForm(formId: number = null, tries = 0): Promise<Eloqua.FormConfig> {
    this.formId = formId;

    if (!this.formId) {
      return Promise.reject('[ELOQUA]: form id not provided');
    }

    return this.cache.getItem(formId).catch(async reason => {
      if (tries > 10) {
        const message = '[ERROR] EloquaService::buildForm aborted after 10 tries.';
        SpsLogger.warn(message, reason);
        return Promise.reject(message);
      }

      const config = await this.fetchAndBuildConfig(formId);
      this.cache.setItem(formId, config);

      return config;
    });
  }

  public async getEmailAddress(): Promise<string> {
    if (!this.user.id) {
      return null;
    }

    return await this.api
      .getEmailAddress(this.user.id)
      .then(({ email }) => email)
      .catch(() => null);
  }

  private async getEloquaUserId(): Promise<string> {
    if (!this.user?.email) {
      return null;
    }

    return this.api
      .getUserId(this.user.email)
      .then(({ id }) => id)
      .catch(() => null);
  }

  private restoreUserId(): void {
    const id: string | null = SpsStorageService.getEntry<{ id: string }>(LOCALSTORAGE_ELOQUA_USER_KEY)?.id ?? null;
    this.user = { id };
  }

  private fetchAndBuildConfig(formId: number): Promise<Eloqua.FormConfig> {
    return this.api
      .getFormConfig(formId)
      .then(srcConfig => this.buildFormConfig(srcConfig))
      .then((config: Eloqua.FormConfig) => {
        config.controls.push(this.buildHoneypot());
        return config;
      })
      .catch(error => {
        SpsLogger.warn(`[ERROR]: EloquaService::fetchAndBuildConfig: ${JSON.stringify(error)}`);
        return null;
      });
  }

  /*
   * Builds FormConfig for a given EloquaSrcConfig
   */

  private async buildFormConfig(srcConfig: Eloqua.SrcConfig = null): Promise<Eloqua.FormConfig> {
    return this.fetchOptionLists(srcConfig.elements)
      .then(() => this.buildControls(srcConfig))
      .then(
        controls =>
          ({
            id: srcConfig.id,
            type: srcConfig.type,
            name: srcConfig.name,
            controls,
          } as Eloqua.FormConfig)
      )
      .catch(error => Promise.reject(`[ELOQUA]: error building controls: ${error}`));
  }

  /*
   * Builds a FormControl from a given EloquaFormField
   */

  private buildFormControl(element: Eloqua.FormField): Eloqua.FormControlConfig {
    const control: Eloqua.FormControlConfig = {
      id: element.id,
      mergeId: element.fieldMergeId,
      label: element.name,
      htmlName: element.htmlName,
      dataType: element.dataType,
      inputType: undefined,
    };

    if (element.defaultValue) {
      control.defaultValue = element.defaultValue;
    }

    switch (element.displayType) {
      case 'checkbox':
        control.inputType = EloquaInputTypeEnum.CHECKBOX;
        break;
      case 'text':
        // Remark: identifying email field by htmlName is not safe..
        control.inputType = element.htmlName === 'emailAddress' ? EloquaInputTypeEnum.EMAIL : EloquaInputTypeEnum.TEXT;
        break;
      case 'singleSelect':
        control.inputType = EloquaInputTypeEnum.SELECT;
        control.selectOptions = this.buildSelectOptions(this.optionLists[element.optionListId]);
        break;
      case 'submit':
        control.inputType = EloquaInputTypeEnum.SUBMIT;
        break;
      case 'hidden':
        control.inputType = EloquaInputTypeEnum.TEXT;
        control.hidden = true;
    }

    this.addValidators(element, control);

    return control;
  }

  private buildHoneypot(): Eloqua.FormControlConfig {
    return {
      id: '-1',
      label: 'Zip Code',
      htmlName: 'zipCode',
      dataType: 'text',
      inputType: EloquaInputTypeEnum.TEXT,
    };
  }

  private addValidators(formField: Eloqua.FormField, eloquaFormControl: Eloqua.FormControlConfig): void {
    if (!formField.validations?.length) {
      return;
    }

    eloquaFormControl.validations = formField.validations.map(this.buildFormControlValidation.bind(this));
  }

  private async fetchOptionLists(elements: Eloqua.SrcConfig['elements']): Promise<void> {
    const flattened: Eloqua.SrcConfig['elements'] = elements.reduce((p, element) => {
      return 'fields' in element ? [...p, ...element.fields] : [...p, element];
    }, []);

    const optionListIds: string[] = flattened
      .filter(element => 'optionListId' in element)
      .map((element: Eloqua.FormField) => element.optionListId);

    if (!optionListIds.length) {
      return;
    }

    this.optionLists = {};

    await Promise.all(
      optionListIds.map(id => this.api.getOptionList(id).then(optionList => (this.optionLists[id] = optionList)))
    ).catch(error => {
      console.warn(`[ELOQUA]: error getting optionList: ${error}`);
      return null;
    });
  }

  private buildControls(srcConfig: Eloqua.SrcConfig): Eloqua.FormControlConfig[] {
    return srcConfig.elements.reduce((collection, element) => {
      if ('fields' in element) {
        return [...collection, ...element.fields.map(this.buildFormControl.bind(this))];
      }

      return [...collection, this.buildFormControl(element)];
    }, []);
  }

  /*
   * Takes a list of options from eloqua form field element and transforms
   * into set of options for select input component
   */

  private buildSelectOptions(optionList: Eloqua.Options.List = null): SelectBoxOption[] {
    if (!optionList) {
      return [];
    }

    return optionList.elements.reduce((p, c) => {
      if (c.value) {
        return [...p, { displayValue: c.displayName, value: c.value }];
      }

      return p;
    }, []);
  }

  /*
   * Transforms an eloqua form validation for field element into eloqua form field
   * validation object that is used to initialize form control validator
   */

  private buildFormControlValidation(element: Eloqua.Validation.FormFieldConfig): Eloqua.Validation.Config {
    const validationConfig: Eloqua.Validation.Config = {
      type: undefined,
      message: undefined,
    };

    const { type, maximum, minimum } = element.condition;

    switch (type) {
      case 'IsRequiredCondition':
        validationConfig.type = ValidationTypesEnum.REQUIRED;
        break;
      case 'IsEmailAddressCondition':
        validationConfig.type = ValidationTypesEnum.EMAIL;
        break;
      case 'TextLengthCondition':
        validationConfig.type = ValidationTypesEnum.TEXT_LENGTH;
        if (maximum) {
          validationConfig.max = parseInt(maximum, 10);
        }
        if (minimum) {
          validationConfig.min = parseInt(minimum, 10);
        }
        break;
      default:
        break;
    }
    validationConfig.message = element.message;
    return validationConfig;
  }

  public submitFormData(
    formConfig: Eloqua.FormConfig,
    form: FormGroup<Eloqua.Form>
  ): Promise<{ success: boolean; body: EloquaSubmissionBody }> {
    const body = this.buildFormSubmissionRequestBody(formConfig, form);

    // Honeypot field filled in - so likely a bot; do not submit the form
    if (body.fieldValues.find(fieldValue => fieldValue.id === '-1')?.value) {
      return Promise.resolve({ success: true, body });
    }

    // Remove honeypot value before submission
    body.fieldValues = body.fieldValues.filter(fieldValue => fieldValue.id !== '-1');

    return (
      this.api
        .submit(this.formId, body)
        .then(() => ({ success: true, body }))
        // TODO Alex: react to form submission
        .catch(() => ({ success: false, body }))
    );
  }

  public submitTrackingForm(assetConfig: GatedDocumentConfig): void {
    const href = decodeURI(assetConfig.link.fileName || assetConfig.link.href);
    const filename = href.split('/').pop();
    const documentType = assetConfig?.documentType || filename.split('.').pop(); // fallback

    const payload: Eloqua.Tracking.Payload = {
      type: 'FormData',
      fieldValues: [
        { type: 'FieldValue', id: '8300', value: this.user.email },
        { type: 'FieldValue', id: '8304', value: filename },
        { type: 'FieldValue', id: '8305', value: documentType },
        { type: 'FieldValue', id: '8306', value: href },
      ],
    };

    this.api.submitTrackingForm(payload);
  }

  /*
   * Builds request body for Eloqua endpoint
   */

  private buildFormSubmissionRequestBody(
    formConfig: Eloqua.FormConfig,
    formGroup: FormGroup<Eloqua.Form>
  ): Eloqua.Submission.Body {
    const payload = {
      type: 'FormData',
      fieldValues: formConfig.controls.reduce(
        (p, c) => this.fieldValueReducer(p, c, formGroup),
        [] as Eloqua.Submission.FieldValue[]
      ),
    };

    this.enricher.addMetadata(payload, formConfig);

    return payload;
  }

  /*
   * Helper function to initialize FieldValue either with default or entered form control data
   */

  private fieldValueReducer(
    fieldValues: Eloqua.Submission.FieldValue[],
    control: Eloqua.FormControlConfig,
    formGroup: FormGroup<Eloqua.Form>
  ): Eloqua.Submission.FieldValue[] {
    const value = formGroup.value[control.htmlName] || control.defaultValue || '';

    return [...fieldValues, { type: 'FieldValue', id: control.id, name: control.htmlName, value }];
  }
}
