import { inject, Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import { catchError, firstValueFrom, map, Observable, of, retry, take, timeout, TimeoutError } from 'rxjs';

import { SpsLogger } from '@lib/utils';

import { Eloqua } from '@interfaces/forms';
import { ApiResponse } from '@models/interfaces';
import { DEFAULT_HTTP_TIMEOUT, GATED_TRACKING_FORM_ID } from '@core/config';
import { environment } from '../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class EloquaApi {
  private http = inject(HttpClient);
  private readonly baseUrl = environment.eloquaApiUrl;

  public async getFormConfig(formId: number, apiVersion = '2.0', tries = 0): Promise<Eloqua.SrcConfig> {
    const path = `${apiVersion}/assets/form/${formId}`;
    try {
      return firstValueFrom(this.get<Eloqua.SrcConfig>(path, { params: { depth: 'partial' } })).then(({ payload, error }) => {
        if (payload) {
          return payload;
        }

        SpsLogger.warn(`[ERROR] EloquaService::getFormConfig ${JSON.stringify(error)}`);
        return Promise.reject(error);
      });
    } catch (error) {
      SpsLogger.warn(`[ERROR] EloquaService::getFormConfig ${JSON.stringify(error)}`);
      return Promise.reject(error);
    }
  }

  public getOptionList(optionListId: string, apiVersion = '2.0'): Promise<Eloqua.Options.List> {
    const path = `${apiVersion}/assets/optionList/${optionListId}`;

    return firstValueFrom(this.get<Eloqua.Options.List>(path).pipe(take(1), catchError(this.mapToErrorResponse))).then(
      ({ payload, error }) => {
        if (payload) {
          return payload;
        }
        SpsLogger.warn(`[ERROR] EloquaService::getOptionList ${JSON.stringify(error)}`);
        return Promise.reject(error);
      }
    );
  }

  public async getEmailAddress(userId: string, apiVersion = '1.0'): Promise<{ email: string }> {
    const path = `${apiVersion}/data/contact/${userId}`;

    return firstValueFrom(this.get<{ name: string }>(path)).then(({ payload, error }) => {
      if (payload) {
        return { email: payload.name };
      }

      SpsLogger.warn(`[ERROR] EloquaService::getEmailAddress ${JSON.stringify(error)}`);
      return Promise.reject(error);
    });
  }

  public getUserId(emailAddress: string, apiVersion = '1.0'): Promise<{ id: string }> {
    const path = `${apiVersion}/data/contacts`;

    return firstValueFrom(
      this.get<{ elements: { id: string }[] }>(path, {
        params: { search: `emailAddress='${emailAddress}'` },
      })
    ).then(({ payload, error }) => {
      if (payload) {
        return { id: payload.elements[0]?.id };
      }

      SpsLogger.warn(`[ERROR] EloquaService::getUserId ${JSON.stringify(error)}`);
      return Promise.reject(error);
    });
  }

  public submitTrackingForm(payload: Eloqua.Tracking.Payload, apiVersion = '2.0'): void {
    const path = `${apiVersion}/data/form/${GATED_TRACKING_FORM_ID}`;
    firstValueFrom(this.post<any>(path, payload)).catch(error => {
      SpsLogger.warn(`[ERROR] EloquaService::submitTrackingForm ${JSON.stringify(error)}`);
    });
  }

  public submit<T>(formId: number, body: Eloqua.Submission.Body, apiVersion = '2.0'): Promise<boolean> {
    const path = `${apiVersion}/data/form/${formId}`;

    return firstValueFrom(this.post<any>(path, body)).then(({ payload, error }) => {
      if (payload) {
        return true;
      }

      SpsLogger.warn(`[ERROR] EloquaService::submit ${JSON.stringify(error)}`);

      return Promise.reject(error);
    });
  }

  private get<T>(path: string, options = {}): Observable<ApiResponse<T>> {
    return this.http.get<T>(`${this.baseUrl}/${path}`, options).pipe(
      timeout(DEFAULT_HTTP_TIMEOUT),
      retry(3),
      take(1),
      map(this.mapToResponse),
      catchError(e => {
        SpsLogger.warn(`[ERROR] EloquaApi::get, ${JSON.stringify(e)}`);

        if (e instanceof TimeoutError) {
          return this.mapToErrorResponse({
            error: new HttpErrorResponse({ status: 408, url: `${this.baseUrl}/${path}`, statusText: e.message }),
          });
        }

        return this.mapToErrorResponse(e);
      })
    );
  }

  private post<T>(path: string, body: any, options = {}): Observable<ApiResponse<T>> {
    return this.http
      .post<T>(`${this.baseUrl}/${path}`, body, options)
      .pipe(take(1), map(this.mapToResponse), timeout(DEFAULT_HTTP_TIMEOUT), catchError(this.mapToErrorResponse));
  }

  private mapToResponse<T>(payload: T): ApiResponse<T> {
    return { payload, error: null };
  }

  private mapToErrorResponse({ error }: { error: HttpErrorResponse }): Observable<ApiResponse<any>> {
    return of({ payload: null, error });
  }
}
