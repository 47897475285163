import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';

import { BehaviorSubject } from 'rxjs';

import { EloquaService } from '@services/eloqua/eloqua.service';
import { SpsStorageService } from '@services/storage/sps-storage.service';

import { COOKIEBOT_ID, ELOQUA_EXPIRY_TIME, GTM_ID, IS_SERVER, LOCALSTORAGE_GATED_KEY } from '@core/config';
import { TGtmEvent } from '@interfaces/gtm';
import { GatedDocumentConfig } from '@models/interfaces';

declare global {
  interface Window {
    dataLayer: TGtmEvent[];
    SPS_GTM_ID: string;
  }
}

@Injectable({ providedIn: 'root' })
export class TrackingService {
  public gatedDownloadsAllowed$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(
    @Inject(IS_SERVER) private isServer: boolean,
    @Inject(DOCUMENT) private document: Document,
    private eloquaService: EloquaService
  ) {}

  public async checkGatedDownloadsAllowed(): Promise<boolean> {
    const allowed = this.isServer ? false : !!SpsStorageService.getEntry(LOCALSTORAGE_GATED_KEY)?.enabled;
    this.gatedDownloadsAllowed$.next(allowed);

    return allowed;
  }

  set gatedDownloadsAllowed(allowed: boolean) {
    if (allowed) {
      const expires = Date.now() + ELOQUA_EXPIRY_TIME;
      SpsStorageService.saveEntry(LOCALSTORAGE_GATED_KEY, { enabled: true, expires });
    }

    this.gatedDownloadsAllowed$.next(allowed);
  }

  public trackGatedDownload(assetConfig: GatedDocumentConfig): void {
    const asset = assetConfig?.link?.fileName || assetConfig?.link?.href;

    if (!asset) {
      return;
    }

    this.eloquaService.getUser().then(user => {
      if (!user?.email || !user?.id) {
        return;
      }

      this.eloquaService.submitTrackingForm(assetConfig);
    });
  }

  public dispatchGTMEvent(gtmEvent: TGtmEvent = null): void {
    if (this.isServer || !gtmEvent || !('dataLayer' in window)) {
      return;
    }

    window.dataLayer.push(gtmEvent);
  }

  public buildGtmScripts(): void {
    this.buildGtmConsentScript();
    this.buildGtmScript();
    this.buildCookiebotScript();
  }

  private buildGtmConsentScript(): void {
    const innerHtml = `
      window.dataLayer = window.dataLayer || [];
      function gtag() { dataLayer.push(arguments) }
      gtag("consent", "default", {
        ad_personalization: "denied",
        ad_storage: "denied",
        ad_user_data: "denied",
        analytics_storage: "denied",
        functionality_storage: "denied",
        personalization_storage: "denied",
        security_storage: "granted",
        wait_for_update: 500
      });
      gtag("set", "ads_data_redaction", true);
    `;
    this.buildScript(innerHtml);
  }

  private buildGtmScript(): void {
    const innerHtml = `
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','${GTM_ID}');
    `;

    this.buildScript(innerHtml);
  }

  private buildCookiebotScript(): void {
    const script = this.document.createElement('script');

    script.id = 'Cookiebot';
    script.async = true;
    script.src = 'https://consent.cookiebot.com/uc.js';
    script.setAttribute('data-cbid', COOKIEBOT_ID);

    this.document.head.appendChild(script);
  }

  private buildScript(innerHtml: string): void {
    const script = this.document.createElement('script');

    script.setAttribute('data-cookieconsent', 'ignore');
    script.innerHTML = innerHtml;

    this.document.head.appendChild(script);
  }
}
